// import 'bootstrap'
import {Carousel} from 'bootstrap';
import './sass/main.scss'


$(document).ready(function () {


    var isTestimonials = false;
    var myCarousel = document.getElementById('sassourel');
    if (!myCarousel) {
        myCarousel = document.getElementById('testimonials_carousel');
        isTestimonials = true;
    }
    if (myCarousel) {

        var config = {
            interval: 4000,
            ride: 'carousel',
            pause: false
        };
        if (isTestimonials) {
            config.interval = 5000;
//            config.pause = "hover";
        }

        var carousel = new Carousel(myCarousel, config);
//        console.log ( 'carousel created with '  , isTestimonials, config );
        $('#sassPrev').on('click', function () {
            carousel.prev();
        })
        $('#sassNext').on('click', function () {
            carousel.next();
        });


        var images = [];

        var list = document.getElementsByClassName("sass-slide");
        for (let i = 0; i < list.length; i++) {
            var style = window.getComputedStyle(list[i]);
            var bg = style['background-image'];
            if (bg) {
                var url = bg.replace('url("', '').replace('")', '');
                if (url) {
                    images.push(url);
                }
            }
        }
        for (let i = 0; i < images.length; i++) {
            var downloadingImage = new Image();
            downloadingImage.src = images[i];
            downloadingImage.addEventListener('load', function (a) {
                //              console.log('loaded', a, url);
            })
        }

    }


});


